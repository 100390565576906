













































































































































































































































































































































































































































.btn-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0px;
  text-align: center;
}
